import React, { useRef, useEffect, useContext } from 'react'
import { sectionContext } from '../../context/section-provider'

const Section = ({ id, children }) => {
  const { refs, addRef, removeRef } = useContext(sectionContext)
  const sectionRef = useRef()
  console.log(refs)
  useEffect(() => {
    addRef(id, sectionRef)
    return () => removeRef(id)
  }, [])
  return (
    <section id={id} ref={sectionRef}>
      {children}
    </section>
  )
}

export default Section
