import React, { useState } from 'react'
import styled from 'styled-components'
import { H4 } from '../../../components/common/fonts'
import InterviewCard from '../../../components/interview-card'
import InterviewDialog from '../../../components/interview-dialog'
import { DEFAULT_BACKGROUND } from '../../../helpers/colors'
import Section from '../../../components/section'
import AttachedBackground from '../../../components/attached-background'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 100vw;
  overflow: hidden;
  background-color: #e6dddbc4;
`
const InterviewsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10%;
`
const SokandeInterviews = ({ node }) => {
  const [selectedInterview, setSelectedInterview] = useState()
  const closeDialog = () => {
    setSelectedInterview()
  }
  const { interviews } = node
  return (
    <Section id='intervjuer'>
      <Wrapper>
          <H4>
            Hur är det att läsa Industriell Ekonomi på Chalmers? Läs intervjuer
            nedan!
          </H4>
          <InterviewsWrapper>
            {interviews.map((interview, index) => (
              <InterviewCard
                key={index}
                handleClick={() => setSelectedInterview(interview)}
                image={interview.interview_imageSharp.childImageSharp.fluid}
                name={interview.name}
                year={interview.year}
              />
            ))}
          </InterviewsWrapper>
          <InterviewDialog interview={selectedInterview} close={closeDialog} />
      </Wrapper>
    </Section>
  )
}

export default SokandeInterviews
