import React from 'react'
import styled from 'styled-components'
import { BreadWrapper, H3 } from '../../../components/common/fonts'
import GalleryScroller from '../../../components/gallery-scroller'
import { RichText } from 'prismic-reactjs'
import { DEFAULT_BACKGROUND } from '../../../helpers/colors'
import TwoColumnInformation from '../../../components/two-column-information'
import InfoRow from '../../../components/info-row'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
`
const EventWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-bottom: 40px;
`

const GalleryInfoRows = ({ title, text1, text2, images, events }) => {
  return (
    <>
      <Wrapper>
        <BreadWrapper color='#4C4D4FE5' maxWidth='1000px'>
          <H3>
            {RichText.asText(title)}
          </H3>
          <TwoColumnInformation text1={text1} text2={text2} />
        </BreadWrapper>
        {images && <GalleryScroller visibleElements={3} images={images} />}
      </Wrapper>
      <EventWrapper>
        {events &&
          events.map((event, i) => (
            <InfoRow
              key={i}
              image={event.fluidImg}
              title={event.title}
              text={event.text}
            />
          ))}
      </EventWrapper>
    </>
  )
}

export default GalleryInfoRows
