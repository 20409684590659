import React from 'react'
import styled from 'styled-components'
import { H1 } from '../../../components/common/fonts'
import { RichText } from 'prismic-reactjs'
import Section from '../../../components/section'
import GalleryInfoRows from './gallery-info-rows'
import AttachedBackground from '../../../components/attached-background'

const SectionHeader = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  padding-bottom: 50px;
`

const SokandeAboutStudentLife = ({ node }) => {
  return (
    <>
      <AttachedBackground
        url={node.student_city_main_image.url}
      >
        <SectionHeader>
          <H1>{RichText.asText(node.student_city_main_title)}</H1>
        </SectionHeader>
      </AttachedBackground>
      <Section id='om-studentstaden'>
        <GalleryInfoRows
          title={node.student_city_title}
          text1={node.student_city_text_1}
          text2={node.student_city_text_2}
          images={node.student_city_images.map(img => ({
            fluid: img.student_city_imageSharp.childImageSharp.fluid
          }))}
          events={node.student_city_events.map(event => ({
            fluidImg: event.student_city_event_imageSharp.childImageSharp.fluid,
            title: event.student_city_event_title,
            text: event.student_city_event_text
          }))}
        />
      </Section>
      <Section id='om-studentlivet'>
        <GalleryInfoRows
          title={node.student_life_title}
          text1={node.student_life_text_1}
          text2={node.student_life_text_2}
          images={
            node.student_life_images &&
            node.student_life_images.map(img => ({
              fluid:
                img.student_life_imageSharp &&
                img.student_life_imageSharp.childImageSharp.fluid
            }))
          }
          events={
            node.student_life_events &&
            node.student_life_events.map(event => ({
              fluidImg:
                event.student_life_event_imageSharp && event.student_life_event_imageSharp.childImageSharp.fluid,
              title: event.student_life_event_title,
              text: event.student_life_event_text
            }))
          }
        />
      </Section>
    </>
  )
}

export default SokandeAboutStudentLife
