import React from 'react'
import styled from 'styled-components'
import { H3, BreadWrapper } from '../../../components/common/fonts'
import { RichText } from 'prismic-reactjs'
import TwoColumnInformation from '../../../components/two-column-information'
import Section from '../../../components/section'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
`

const SokandeAboutStudy = ({ node }) => {
  const { about_study_title, about_study_text, about_study_text_2 } = node
  return (
    <Section id='studier'>
    <Wrapper>
      <H3 underline>{RichText.asText(about_study_title)}</H3>
      <BreadWrapper maxWidth='1000px' row color='#4C4D4FE5'>
        <TwoColumnInformation
          text1={about_study_text}
          text2={about_study_text_2}
        />
      </BreadWrapper>
    </Wrapper>
    </Section>
  )
}

export default SokandeAboutStudy
