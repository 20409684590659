import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'
import { breakpoints } from '../../helpers/media'
import CustomRichText from '../custom-rich-text'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: ${breakpoints.md}px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  margin: 10px;
  line-height: 35px;
  @media only screen and (max-width: ${breakpoints.md}px) {
    line-height: 20px;
    font-size: 16px;
  }
`
const TwoColumnInformation = ({ text1, text2 }) => {
  return (
    <Wrapper>
      <Column>
        <CustomRichText richText={text1} />
      </Column>
      {text2 && (
        <Column>
          <CustomRichText richText={text2} />
        </Column>
      )}
       
    </Wrapper>
  )
}

export default TwoColumnInformation
