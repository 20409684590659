import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { RichText } from 'prismic-reactjs'
import { breakpoints } from '../../helpers/media'
import { DEFAULT_GREY_TITLE } from '../../helpers/colors'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 269px;
  margin: 10px;
  max-width: 30vw;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`

const Title = styled.p`
  font-size: 20px;
  color: ${DEFAULT_GREY_TITLE};
  padding: 10px;
  @media only screen and (max-width: ${breakpoints.md}px) {
    font-size: 16px;
  }
`
const InterviewCard = ({ handleClick, image, name, year }) => {
  return (
    <Wrapper onClick={handleClick}>
      <Img
        fluid={image}
        style={{ width: '100%', height: '100%' }}
        imgStyle={{ objectFit: 'contain' }}
      />
      <Title>
        {RichText.asText(name).split(' ')[0]}, {RichText.asText(year)}
      </Title>
    </Wrapper>
  )
}

export default InterviewCard
