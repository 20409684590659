import React, { useState } from 'react'
import styled from 'styled-components'
import CustomRichText from '../custom-rich-text'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  height: fit-content;
  margin-left: 7.5px;
  margin-right: 7.5px;
  background-color: #f7f4f4;
  box-shadow: 0px 3px 6px #00000043
`

const TitleWrapper = styled.div(
  ({ backgroundColor, color }) => `
  width: 100%;
  min-height: 45px;
  padding-top: 12px;
  text-align: center;
  background-color: ${backgroundColor || '#9F5CA7'};
`
)

const Title = styled.h5`
  color: white;
  font-size: 22px;
`

const TextWrapper = styled.div(
  ({ collapsed }) => `
  font-size: 15px;
  padding: 15px;
  height: '385px';
  overflow: hidden;
  transition: height .3s ease-in-out;
`
)
const Collapsible = ({ title, text, backgroundColor }) => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <Wrapper onClick={() => setCollapsed(!collapsed)}>
      <TitleWrapper backgroundColor={backgroundColor}>
        <Title>{title}</Title>
      </TitleWrapper>
      <TextWrapper collapsed={collapsed}>
        <CustomRichText richText={text}/>
      </TextWrapper>
    </Wrapper>
  )
}
export default Collapsible
