import React from 'react'
import { Dialog } from '@material-ui/core'
import Img from 'gatsby-image'
import Slide from '@material-ui/core/Slide'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import { H4, BreadWrapper } from '../common/fonts'
import { breakpoints } from '../../helpers/media'
import ClickableImg from '../clickable-img'
import CustomRichText from '../custom-rich-text'
const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const Wrapper = styled.div`
  display: block;
`

const DialogHeader = styled.div`
  position: relative;
  max-height: 70vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  @media only screen and (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    overflow: scroll;
  }
`

const TitleWrapper = styled.div`
  display: block;
  flex-direction: column;
  overflow-y: scroll;
  width: 50%;
  padding-bottom: 50px;
  @media only screen and (max-width: ${breakpoints.md}px) {
    width: 100%;
    overflow-y: visible;
  }
`

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  align-self: center;
  @media only screen and (max-width: ${breakpoints.md}px) {
    display: none;
  }
`
const InterviewCard = ({ interview, close }) => {
  return (
    <Dialog
      open={!!interview}
      onClose={close}
      maxWidth='lg'
      fullWidth
      TransitionComponent={Transition}
    >
      {!!interview && (
        <Wrapper>
          <DialogHeader>
            <TitleWrapper>
              <H4>{`${RichText.asText(interview.name)}, ${RichText.asText(
                interview.year
              )}`}</H4>
              <BreadWrapper alignItems='flex-start' fontSize='18px' color='#4C4D4FE5'>
                <CustomRichText richText={interview.interview_text}/>
              </BreadWrapper>
            </TitleWrapper>
            <ImgWrapper>
              <ClickableImg
                fluid={interview.interview_imageSharp.childImageSharp.fluid}
                style={{ width: '100%', height: '100%' }}
              />
            </ImgWrapper>
          </DialogHeader>
        </Wrapper>
      )}
    </Dialog>
  )
}

export default InterviewCard
