import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { DEFAULT_BACKGROUND, DEFAULT_COLOR } from '../../helpers/colors'
import { breakpoints } from '../../helpers/media'
import Section from '../section'
import { RichText } from 'prismic-reactjs'

const Wrapper = styled.div`
  overflow: hidden;
  width: 100vw;
  margin-bottom: 50px;
  padding: 20px;
  background-color: lightgrey;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ad5389; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #3c1053,
    #ad5389
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #3c1053,
    #ad5389
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  @media only screen and (max-width: ${breakpoints.lg}px) {
    flex-direction: column;
  }
`

const CircleWrapper = styled.div`
  max-height: 80vw;
  max-width: 80vw;
  height: 500px;
  width: 500px;
  border-radius: 500px;
  box-shadow: 2px 2px 6px 1px #0000005e;
  margin-bottom: 200px;
  margin-top: 200px;
  @media only screen and (min-width: ${breakpoints.lg}px) {
    margin-right: 100px;
  }
`
const Circle = styled.div`
  max-height: 80vw;
  max-width: 80vw;
  position: relative;
  height: 500px;
  width: 500px;
  background-color: white;
  border: solid 15px ${DEFAULT_BACKGROUND};
  border-radius: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-shadow: 2px 2px 4px 1px #0000005e inset;
`

const CircleText = styled.p(
  ({ selected }) => `
  position: absolute;
  font-weight: bold;
  opacity: ${selected ? 1 : 0};
  max-width: 75%;
  max-height: 300px;
  transform: scale(${selected ? 1 : 0});
  transition: transform 0.5s ease-in-out;
  overflow: hidden;
  color: ${DEFAULT_COLOR};
  font-size: 10px;
  text-align: center;
  @media only screen and (min-width: ${breakpoints.md}px) {
    font-size: 22px;
  }
`
)

const Slang = styled.div(
  ({ rot }) => `
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: -100px;
  z-index: -10;
  width: 1px;
  height: 350px;
  transform: rotate(${rot}deg);
  transition: transform 0.5s ease-in-out;
  transform-origin: bottom right;
  animation-name: opacityIn;
  animation-duration: ${rot / 100 + 2}s;
  animation-iteration-count: 1;
  @media only screen and (max-width: ${breakpoints.md}px) {
    height: calc(50vw + 50px);
  }
`
)

const SlangTitle = styled.p(
  ({ rot, selected }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-style: italic;
  border: ${selected ? 'solid 2px ' + DEFAULT_BACKGROUND : ''};
  align-items: center;
  position: absolute;
  padding: 20px;
  top: -10px;
  color: white;
  opacity: ${selected ? 1 : 0.3};
  background-color: ${DEFAULT_COLOR};
  border-radius: 100px;
  transform: rotate(${360 - rot}deg) scale(${selected ? 2.2 : 1});
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  box-shadow: 2px 2px 4px 1px #0000003e;
  height: 75px;
  width:  75px;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
`
)

const SiteTitle = styled.h1`
  width: 550px;
  color: white;
  animation: typing 1s steps(40);
  overflow: hidden;
  font-size: 75px;

  @media only screen and (min-width: ${breakpoints.lg}px) {
    white-space: nowrap;
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    text-align: center;
    font-size: 45px;
    margin-top: 50px;
  }
`

const Divider = styled.div`
  width: 3px;
  height: 150px;
  background-color: white;
  margin-right: 200px;
  @media only screen and (max-width: ${breakpoints.lg}px) {
    width: 150px;
    height: 3px;
    margin-right: 0px;
    margin-top: 25px;
  }
`

const SpinInfo = ({ infos }) => {
  const [selectedInfo, setSelectedInfo] = useState({ ...infos[0], i: 0 })
  const interval = useRef(null)
  const clear = () => {
    clearInterval(interval.current)
    interval.current = null
  }
  useEffect(() => {
    interval.current = setInterval(() => {
      const random = Math.floor(Math.random() * Math.floor(infos.length - 1))
      setSelectedInfo({ ...infos[random], i: random })
    }, 3500)

    return () => {
      clearInterval(interval.current)
      interval.current = null
    }
  }, [])
  return (
    <Section id='chalmers-slang'>
      <Wrapper>
        <SiteTitle>Chalmers-slang</SiteTitle>
        <Divider />
        <CircleWrapper>
          <Circle>
            {infos.map((slang, i) => {
              const rot =
                i * (360 / infos.length) -
                (selectedInfo ? (selectedInfo.i + 1) * (360 / infos.length) : 0)
              return (
                <Slang
                  rot={rot}
                  onClick={() => {
                    clear()
                    setSelectedInfo({ ...slang, i })
                  }}
                >
                  <SlangTitle rot={rot} selected={selectedInfo.i === i}>
                    {RichText.asText(slang.chalmers_slang_title)}
                  </SlangTitle>
                </Slang>
              )
            })}
            {infos.map((info, i) => (
              <CircleText selected={i === selectedInfo.i}>
                {RichText.asText(info.chalmers_slang_text)}
              </CircleText>
            ))}
          </Circle>
        </CircleWrapper>
      </Wrapper>
    </Section>
  )
}

export default SpinInfo
