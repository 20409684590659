import React from 'react'
import { H3 } from '../common/fonts'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'
import { DEFAULT_COLOR } from '../../helpers/colors'
import { breakpoints } from '../../helpers/media'
import AttachedBackground from '../attached-background'
import CustomRichText from '../custom-rich-text'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  color: #606676;
  overflow: hidden;
`

const LapseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1025px;
  max-width: 100vw;
  position: relative;
  ::after {
    display: block;
    position: absolute;
    left: calc(50% - 2px);
    top: 20px;
    height: calc(100% - 275px);
    width: 4px;
    min-width: 4px;
    max-width: 4px;
    content: '';
    background-color: white;
    @media only screen and (max-width: ${breakpoints.md}px) {
      left: auto;
      right: 5vw;
    }
  }
`
const TimeSection = styled.div(
  ({ invert: end }) => `
  position: relative;
  align-self: ${end ? 'flex-end' : 'flex-start'};
  width: 450px;
  max-width: 43vw;
  padding: 15px;
  background-color: #ffffffe0;
  border-radius: 18px;
  & h1 {
    font-size: 22px;
  }
  & h6 {
    margin: 20px 0px 10px 0px;
    font-size: 14px;
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    align-self: flex-start;
    width: 80vw;
    max-width: 80vw;
    margin-bottom: 10px;
    margin-left: 5vw;
  }
  ::before {
    content: '';
    position: absolute;
    right: ${end ? '' : '-73px'};
    left: ${end ? '-73px' : ''};
    top: 20px;
    background-color: white;
    border-radius: 50px;
    width: 22px;
    height: 22px;
    border: solid 4px ${DEFAULT_COLOR};
    z-index: 10;
    @media only screen and (max-width: ${breakpoints.md}px) {
      left: calc(90vw - 13px);
      right: auto;
    }
  }
  ::after {
    position: absolute;
    right: ${end ? '' : '-20px'};
    left: ${end ? '-20px' : ''};
    transform: rotate(${end ? '180' : 0}deg);
    top: 18px;
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #ffffffe0;
    @media only screen and (max-width: ${breakpoints.md}px) {
      right: -20px;
      left: auto;
      transform: rotate(0deg);
    }
  }
`
)

const DestinationSection = styled.div(
  ({}) => `
  position: relative;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  max-width: 100vw;
  height: 150px;
  margin-top: 75px;
  margin-bottom: 75px;
  padding: 15px;
  border-radius: 18px;
  background-color: #ffffffe0;
  text-align: center;
  & h3 {
    font-size: 26px;
  }
  & h4 {
    margin: 15px 0px 10px 0px;
    font-size: 16px;
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    border-radius: 10px;
  }
  ::before {
    content: '';
    position: absolute;
    top: -50px;
    background-color: white;
    border-radius: 50px;
    width: 22px;
    height: 22px;
    border: solid 4px ${DEFAULT_COLOR};
    z-index: 10;
    @media only screen and (max-width: ${breakpoints.md}px) {
      left: calc(95vw - 13px);
      right: auto;
      top: -40px;
    }
  }
  ::after {
    position: absolute;
    align-self: center;
    transform: rotate(-90deg);
    top: -24px;
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #ffffffe0;
    @media only screen and (max-width: ${breakpoints.md}px) {
      left: calc(95vw - 12px);
      right: auto;
    }
  }
`
)
const Timelapse = ({ timelapse }) => {
  return (
    <AttachedBackground url={timelapse.background_image.url} attached>
      <Wrapper>
        <H3 color='white'>{RichText.asText(timelapse.title)}</H3>
        <LapseWrapper>
          {timelapse.frames.map((frame, index) => (
            <TimeSection key={index} invert={index % 2 === 1}>
              {RichText.render(frame.frame_title)}
              <CustomRichText richText={frame.frame_text} />
            </TimeSection>
          ))}
          <DestinationSection>
            <img
              style={{ height: 45 }}
              src='https://i.ibb.co/Tbw072C/mortarboard.png'
              alt='finish'
            />
            {RichText.render(timelapse.destination_title)}
            <p>{RichText.asText(timelapse.destination_subtitle)}</p>
          </DestinationSection>
        </LapseWrapper>
      </Wrapper>
    </AttachedBackground>
  )
}
export default Timelapse
