import React from 'react'
import styled from 'styled-components'
import { H3, BreadWrapper } from '../../../components/common/fonts'
import Badge from '../../../components/badge'
import Collapsible from '../../../components/collapsible'
import { DEFAULT_COLOR } from '../../../helpers/colors'
import { RichText } from 'prismic-reactjs'
import Section from '../../../components/section'
import useTranslation from '../../../hooks/use-translation'
import { breakpoints } from '../../../helpers/media'
import CustomRichText from '../../../components/custom-rich-text'

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`

const CollapsibleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 80px;
  max-width: 100vw;
  padding-bottom: 80px;
  overflow-x: scroll;
  @media only screen and (max-width: ${breakpoints.lg}px) {
    justify-content: flex-start;
    padding-left: 0px;
    padding-right: 0px;
  }
`

const Margin = styled.div`
  min-width: 1px;
  min-height: 1px;
  background-color: white;
`

const SokandeAboutProgram = ({ node }) => {
  const t = useTranslation()
  const { about_program_title, about_program_text, about_program_cards } = node
  return (
    <Section id='om-programmet'>
      <Wrapper>
        <H3 underline>{RichText.asText(about_program_title)}</H3>
        <BreadWrapper maxWidth='1000px' color='#4C4D4FE5'>
          <CustomRichText richText={about_program_text} />
          <Badge marginTop="40px" href="https://www.chalmers.se/utbildning/hitta-program/industriell-ekonomi-civilingenjor/" height='45px' color='white' backgroundColor={DEFAULT_COLOR}>
            {t`The Education`}
          </Badge>
        </BreadWrapper>
        <CollapsibleWrapper>
          {about_program_cards.map(card => (
            <Collapsible
              key={RichText.asText(card.card_title)}
              title={RichText.asText(card.card_title)}
              text={card.card_text}
            />
          ))}
          <Margin />
        </CollapsibleWrapper>
      </Wrapper>
    </Section>
  )
}

export default SokandeAboutProgram
