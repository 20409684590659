import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import useLanguage from '../hooks/use-language'
import SokandeHero from '../views/sokande/sokande-hero'
import SokandeTeaser from '../views/sokande/sokande-teaser'
import SokandeAboutProgram from '../views/sokande/sokande-about-program'
import Timelapse from '../components/timelapse'
import SokandeAboutStudy from '../views/sokande/sokande-about-study'
import SokandeAboutStudentLife from '../views/sokande/sokande-about-student-life'
import SokandeInterviews from '../views/sokande/sokande-interviews'
import SpinInfo from '../components/spin-info'
import SokandeYoutube from '../views/sokande/sokande-youtube'

const IndexPage = ({ data }) => {
  const language = useLanguage()
  let node =
    data.prismic.allSokandes.edges.filter(
      x => x.node._meta.lang === language.locale
    )[0].node || data.prismic.allSokandes.edges[0].node
  let timelapse =
    data.prismic.allTimelapses.edges.filter(
      x => x.node._meta.lang === language.locale
    )[0].node || data.prismic.allTimelapses.edges[0].node
  return (
    <Layout>
      <SEO title='Sökande' />
      <SokandeHero
        url={node.hero_background_image.url}
        title={node.hero_title}
      />
      <SokandeTeaser teasers={node.teasers} />
      <SokandeAboutProgram node={node} />
      <Timelapse timelapse={timelapse} />
      <SokandeAboutStudy node={node} />
      <SokandeAboutStudentLife node={node} />
      {/* <SpinInfo infos={node.chalmers_slang} />*/}
      <SokandeYoutube url={node.youtube_link} title={node.youtube_title} description={node.youtube_description} />
      <SokandeInterviews node={node} />
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allSokandes {
        edges {
          node {
            _meta {
              lang
            }
            hero_background_image
            hero_background_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                 ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            hero_title
            teasers {
              teaser_title
              teaser_text
              teaser_long_text
              teaser_link
              teaser_link_href {
                ... on PRISMIC__ExternalLink {
                  _linkType
                  url
                }
              }
              teaser_image
              teaser_imageSharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 900) {
                   ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            about_program_cards {
              card_text
              card_title
            }
            about_program_text
            about_program_title
            about_study_text
            about_study_text_2
            about_study_title
            student_city_main_title
            student_city_main_image
            student_city_main_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                 ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            student_city_title
            student_city_text_1
            student_city_text_2
            student_city_images {
              student_city_image
              student_city_imageSharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 900) {
                   ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            student_city_events {
              student_city_event_title
              student_city_event_text
              student_city_event_image
              student_city_event_imageSharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 400) {
                   ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            student_life_title
            student_life_text_1
            student_life_text_2
            student_life_images {
              student_life_image
              student_life_imageSharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 900) {
                   ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            student_life_events {
              student_life_event_title
              student_life_event_text
              student_life_event_image
              student_life_event_imageSharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 400) {
                   ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            chalmers_slang {
              chalmers_slang_title
              chalmers_slang_text
            }
            youtube_link
            youtube_title
            youtube_description
            interviews {
              name
              year
              interview_text
              interview_image
              interview_imageSharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 900) {
                   ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      allTimelapses {
        edges {
          node {
            _meta {
              lang
            }
            background_image
            background_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                 ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            destination_subtitle
            destination_title
            frames {
              frame_text
              frame_title
            }
          }
        }
      }
    }
  }
`

export default IndexPage
