import React from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import { breakpoints } from '../../../helpers/media'
import { RichText } from 'prismic-reactjs'
import Section from '../../../components/section'
import { DEFAULT_GREY } from '../../../helpers/colors'

const Wrapper = styled.div`
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  color: ${DEFAULT_GREY};
  @media only screen and (min-width: ${breakpoints.md}px) {
    flex-wrap: wrap;
  }
`

const PlayerWrapper = styled.div`
  max-width: 100vw;
  overflow: hidden;
  @media only screen and (min-width: ${breakpoints.md}px) {
    width: 1240px;
    min-height: 600px;
    height: 420px;
    padding: 50px;
  }
`
const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px 20px 0px;
  max-width: 80vw;
  @media only screen and (min-width: ${breakpoints.md}px) {
    width: 850px;
  }
`

const Title = styled.h3`
  margin-bottom: 10px;
  font-size: 18px;
  @media only screen and (min-width: ${breakpoints.md}px) {
    font-size: 25px;
  }
`
const Description = styled.div`
  font-size: 12px;
  @media only screen and (min-width: ${breakpoints.md}px) {
    font-size: 20px;
    & p {
      margin-bottom: 5px;
    }
  }
`
const SokandeYoutube = ({ url, title, description }) => {
  return (
    <Section id='youtube'>
      <Wrapper>
        <PlayerWrapper>
          <ReactPlayer
            width='100%'
            height='100%'
            url='https://www.youtube.com/watch?list=PLleeUtnvdZtWyJI16RbJEkexGcQnApvsu&v=s0lGTwYWD_I'
          />
        </PlayerWrapper>
        <DescriptionWrapper>
          <Title>{RichText.asText(title)}</Title>
          <Description>{RichText.render(description)}</Description>
        </DescriptionWrapper>
      </Wrapper>
    </Section>
  )
}

export default SokandeYoutube
